@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    
  }
}

.container.show{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101000;
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  background-color: rgba(1, 35, 16, 0.6);
  backdrop-filter: blur(3px);
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
}
.container{
  visibility: hidden;
  opacity: 0;
  transition: all 1s ease-in-out;
  animation: fadeIn 1s ease-in-out;
}

.modal.show {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  z-index: 101001 !important;
  opacity: 1 !important;
  visibility: visible;
  filter: blur(0.2);
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);    
  font-family: 'Mulish', sans-serif;
}
.modal{
  display: none;
  visibility: hidden;
  opacity: 0;
}

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background:#FCF3F8;
  width:30px;
  height: 30px;
  color: #9D0045;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);    
  transition: 0.5s;
}

.closeBtn:hover,
.closeBtn:focus {
  color: #F5FFF9;
  background:#B50050;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s;
}

.close{  
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  width: 18px;
  text-align: center;  
}

.modal img{
  width: 90vw;
  height: auto;
}