.statsTitle {
  font-weight: 800;
  font-size: 24px;
  line-height: 117%;
}

.stats {
  display: flex;
  align-items: center;
}

.img {
  height: 50px;
  width: fit-content;
  margin-right: 16px;
}

.statsDescript {
  margin: 0;
  font-size: 20px;
  line-height: 120%;
  margin-top: 10px;
  text-align: center;
}

.statsItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.statsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; 
}

.link{
  text-decoration: none;
  color: #E00061;
}
.link:hover{
  color: #9D0045;
}


@media (min-width: 768px) {
  .statsTitle {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .statsItemWrapper {
    display: flex;
    align-items: unset;
    justify-content: flex-start;
    width: 100%;
  }
  .statsItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%; 
  }

  .statsDescript {
    text-align: left;
  }
}

@media (min-width: 1400px) {
  .statsTitle {
    font-size: 36px;
  }
}