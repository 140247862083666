.statistics {
  width:100%;
  background-color: #F4F8FB;
    font-family: 'Mulish', sans-serif;
  font-style: normal;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;
}

.statsContainer{
  width: 100%;
}

.statsTitle {
  font-size: 27px;
  text-align: center;
  margin-bottom: 45px;
}

.carousel {
  height: 170px;
  padding: 0 15px;
}

@media (min-width: 340px) {
  .carousel {
    height: 170px;
    padding: 0 25px;
  }
}

@media (min-width: 380px) {
  .carousel {
    height: 140px;
  }
}

@media (min-width: 428px) {
  .statsTitle {
   max-width: 560px;
  }
}
@media (min-width: 480px) {
  .carousel {
    height: 130px;
  }
}

@media (min-width: 690px) {
  .carousel {
    height: 120px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .statsTitle {
    font-size: 32px;
    margin-bottom: 65px;
  }

}

@media (min-width: 1200px) {
  .container {
    flex-direction: row;
    align-items: unset;
    padding-top: 125px;
    padding-bottom: 125px;
  }

  .statsContainer {
    width: 100%;
  }

  .statsTitle {
    min-width: 430px;
    text-align: left;
    margin-right: 80px;
    margin-bottom: 0;
  }
}

@media (min-width: 1400px) {
  .statsTitle {
    margin-right: 100px;
  }
}

@media (min-width: 1420px) {
}

@media (min-width: 1600px) {  
  .carousel {
    height: 100px;
  }
}