.expertise {  
  background-color: #F5FFF9;
  font-family: 'Mulish', sans-serif;
}

.container {
  width:100%;
}

.showmoreText{
  font-style: italic;
}

.showmoreWrapper,
.showlessWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px; 
}

.showlessWrapper{
  margin-top: 15px; 
}

.showlessWrapper{
  flex-direction: column-reverse; 
}

.dropdownIconRotate{
  transform: rotate(180deg);
}

.rectContainer{
  padding: 25px 0px 0px 0px;
  /* width: 100%; */
}

.img, .imgZoom{
  width: 100%;
  height: auto; 
}

.imgZoom {
  cursor: -moz-zoom-in; 
  cursor: -webkit-zoom-in; 
  cursor: zoom-in;
}

.textWrapper {
  height: 100%;
  text-align:  center;
}

.fade {
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.btnWrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.btnSecondary {
  margin-top: 15px;
}

.btnMain,
.btnSecondary {
  min-width: 230px;
}

.modalContent{
  max-width: 90vw;
}

@media (min-width: 580px)  {
  .btnWrapper { 
    flex-direction: row;
  }

  .btnSecondary {
    margin-top: 0;
      margin-left: 20px;
  }

  .btnMain,
  .btnSecondary {
    min-width: unset;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .rectContainer{
    max-width: 648px;
  }

  .text{
    max-width: 648px;
  }
}

@media (min-width: 1200px) {
  .rectContainer{
    max-width: 960px;
  }

  .text{
    max-width: 960px;
  }

  .modalContent{
    max-width: 100%;
  }
}

@media (min-width: 1400px) {
  .container{
    flex-direction: row-reverse !important;
    align-items: flex-start !important;    
    min-height: 100%;
  }

  .textWrapper {
    text-align:  left;
    width: 50%;
    height: auto;
  }

  .rectContainer{
    padding: 0;
    width: 50%;
    margin-right: 20px;    
  }

  .textWrapper p {
    text-align: left;
  }

  .btnL {
    margin-top: 25px;
  }
}

@media (min-width: 1600px) {
  .rectContainer{
    margin-right: 30px;    
  }

  .btnL {
    margin-top: 35px;
  }
}