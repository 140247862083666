.cookies {
  margin-left: 10px;
}

a {
  text-decoration: none;
}

.link {
  color: #F5FFF9;
  text-decoration: none;
}

link:visited {
  color:#F5D1DE;
}
  
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;;
  backdrop-filter: blur(6px)
}
  
.clutchSection {
  /* height: 60px; */
  display: flex;
  justify-content: center;
}
