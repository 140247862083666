.clients {
  display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Mulish', sans-serif;
    color: #152A3D;
    overflow: hidden;
    text-align: center;
}

.marqueeWrapper {
  margin-top: 8vw;
}

.logoWrapper {
  margin-left: 40px;
}
.logo {
  height: 30px;
}

@media (min-width: 768px)  {
.marqueeWrapper {
    margin-top: 50px;
    }
    
  .logo {
    height: 60px;
  }
}  

@media (min-width: 1200px) {
.marqueeWrapper {
    margin-top: 60px;
    }

        .logoWrapper {
          margin-left: 60px;
  }
  
}

@media (min-width: 1400px) {
}

@media (min-width: 1920px) {
.marqueeWrapper {
    margin-top: 95px;
}
}